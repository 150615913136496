import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'underscore';
import { makeGetMarket } from 'sportsbook-markets-selector';
import { makeGetComponentById } from '../selectors';
import buildMarket from '../factories/market';

// Just some naming in order to make clear that the returned element  of buildMarket
// will be the returned element of the MarketContainer
const MarketContainer = buildMarket;

MarketContainer.propTypes = {
  id: PropTypes.number.isRequired,
  componentId: PropTypes.number.isRequired,
  des: PropTypes.string.isRequired,
  p: PropTypes.string.isRequired,
  o: PropTypes.instanceOf(Array).isRequired,
};

const makeMapStateToProps = () => {
  const getComponentById = makeGetComponentById();
  const getMarket = makeGetMarket();

  return (state, { id, componentId }) => {
    const {
      sport: { layout, numberOfOutcomes },
      markets,
    } = getComponentById(state, componentId);

    return {
      ...getMarket(state, { id }),
      ...markets[id],
      layout,
      numberOfOutcomes,
    };
  };
};

export default connect(makeMapStateToProps, null, null, {
  areStatePropsEqual: isEqual,
})(MarketContainer);
