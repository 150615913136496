import PropTypes from 'prop-types';
import SportsbookMarket from 'sportsbook-market-component';
import Outcomes from '../outcomes';

const MultiMarket = ({
  opponentADescription, opponentBDescription, des, ...props
}) => (
  <div className="coupon-multi-market__market">
    <p>{des}</p>
    <SportsbookMarket
      {...props}
      className="market-view-body-wrapper"
      outcomeClassName="bvs-button-sport outcome-button"
    >
      <Outcomes />
    </SportsbookMarket>
  </div>
);

MultiMarket.propTypes = {
  des: PropTypes.string.isRequired,
  opponentADescription: PropTypes.string.isRequired,
  opponentBDescription: PropTypes.string.isRequired,
};

export default MultiMarket;
