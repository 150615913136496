import PropTypes from 'prop-types';

import { formatPrice } from 'sportsbook-helpers';
import { EnhancedPrice } from 'sportsbook-components';

const Outcome = ({
  title, pr, h, fractionalEnhancedOdds,
}) => (
  <div className="outcome-n-wrapper">
    <span className="outcome-n-description">{title}</span>
    <span className="outcome-n-price">
      {
        fractionalEnhancedOdds
          ? (
            <EnhancedPrice
              enhancedPrice={fractionalEnhancedOdds}
              oldPrice={formatPrice(pr)}
            />
          )
          : (h && '-') || (pr && formatPrice(pr)) || 'SP'
      }
    </span>
  </div>
);

Outcome.propTypes = {
  title: PropTypes.string,
  pr: PropTypes.string.isRequired,
  h: PropTypes.bool.isRequired,
  fractionalEnhancedOdds: PropTypes.string,
};

Outcome.defaultProps = {
  fractionalEnhancedOdds: null,
  title: null,
};

export default Outcome;
