import SportsbookMarket from 'sportsbook-market-component';
import Outcomes from '../outcomes';

const SingleMarket3Column = (props) => (
  <SportsbookMarket
    {...props}
    className="market-view-body-wrapper outcome-3cols"
    outcomeClassName="bvs-button-sport outcome-button-1x2"
  >
    <Outcomes />
  </SportsbookMarket>
);

export default SingleMarket3Column;
