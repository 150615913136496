import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'underscore';

import SportsbookMarket from 'sportsbook-market-component';
import Outcomes from '../outcomes';
import { makeGetOutcomesByIdsSortByPrice } from '../../selectors';

const SingleMarket1Column = (props) => (
  <SportsbookMarket
    {...props}
    className="market-view-body-wrapper"
    outcomeClassName="bvs-button-sport outcome-button"
  >
    <Outcomes />
  </SportsbookMarket>
);

SingleMarket1Column.propTypes = {
  numberOfOutcomes: PropTypes.number.isRequired,
};

const makeMapStateToProps = () => {
  const getOutcomesById = makeGetOutcomesByIdsSortByPrice();

  return (state, { o: outcomesIds, numberOfOutcomes }) => ({
    o: getOutcomesById(state, { outcomesIds, numberOfOutcomes }),
  });
};

export default connect(makeMapStateToProps, null, null, {
  areStatePropsEqual: isEqual,
})(SingleMarket1Column);
