import PropTypes from 'prop-types';
import MarketContainer from '../market_container';

const MultiMarketLayout = ({
  marketIds, opponentADescription, opponentBDescription, ...props
}) => (
  <div className="coupon-multi-market">
    <div className="coupon-multi-market__event-names">
      <p>{opponentADescription}</p>
      <p>{opponentBDescription}</p>
    </div>
    <div className={`coupon-multi-market__markets has-${marketIds.length}-columns`}>
      {marketIds.map((id) => (<MarketContainer key={id} id={id} {...props} />))}
    </div>
  </div>
);

MultiMarketLayout.propTypes = {
  marketIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  opponentADescription: PropTypes.string.isRequired,
  opponentBDescription: PropTypes.string.isRequired,
};

export default MultiMarketLayout;
