import SportsbookMarket from 'sportsbook-market-component';
import Outcomes from '../outcomes';

const SingleMarket2Column = (props) => (
  <SportsbookMarket
    {...props}
    className="market-view-body-wrapper outcome-2cols"
    outcomeClassName="outcome-button-2cols bvs-button-sport outcome-button-1x2"
  >
    <Outcomes />
  </SportsbookMarket>
);

export default SingleMarket2Column;
