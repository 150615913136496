import PropTypes from 'prop-types';
import { isMarketSuspended } from 'sportsbook-helpers';
import OutcomeContainer from './outcome_container';
import SuspendedMarket from './suspended_market';

const Outcomes = ({
  componentId, st, o: outcomesIds, outcomeClassName,
}) => {
  if (isMarketSuspended({ st })) return <SuspendedMarket />;

  return (
    <div className="outcomes">
      {outcomesIds.map((outcomeId) => (
        <OutcomeContainer
          id={outcomeId}
          componentId={componentId}
          outcomeClassName={outcomeClassName}
          key={outcomeId}
        />
      ))}
    </div>
  );
};

Outcomes.propTypes = {
  componentId: PropTypes.number.isRequired,
  st: PropTypes.number,
  outcomeClassName: PropTypes.string,
  o: PropTypes.instanceOf(Array),
};

Outcomes.defaultProps = {
  st: null,
  outcomeClassName: null,
  o: null,
};

export default Outcomes;
